import React, { useEffect, useState } from 'react'
import moment from "moment";
import defaultUser from '../../../assets/images/A.png'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import './QueryBox.scss'
import * as routes from '../../../routes/route'

function QueryBox({item, type}) {

    const [imageUrl, setImageUrl] = useState(null);
    const navigate = useNavigate()
    const checkIn = moment(item?.check_in).format('MMM DD, dddd')
    const checkOut = moment(item?.check_out).format('MMM DD, dddd')
    const nights = moment(item?.check_out).diff(moment(item?.check_in), 'day')

    useEffect(()=>{
        
        if(item.profile_pic?.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, item.profile_pic);
    
                getDownloadURL(imageRef).then((url) => {
                    setImageUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){
                
            }
        }
  }, [item]);
  
  
  return (
    <div className='queries-container'>
        <div className='query-data'>
            <div className="d-flex align-items-center h-fit">
                <div className={`profile-img ${(type==='active') ? (moment().diff(moment(item?.last_msg_datetime), 'hour') > 24 ? 'border-danger' : 'success') : ''}`}>
                    <img src={!item.profile_pic ? defaultUser : imageUrl } alt=""  width={"100%"}/>
                </div> 
                <div className='profile-content'>
                    <div className="query-subject">{item.subject}</div>
                    <div className="query-name">{item.guest_name}</div>
                    <div className="query-order">{item.program_title}</div>
                </div>
            </div>
            <div>
                {
                    type==='active' ?
                    <div className='time'>{item?.last_msg_datetime ? moment(item?.last_msg_datetime).fromNow() : ''}</div>
                    : null
                }
            </div>
        </div>
    </div>
  )
}

export default QueryBox