import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import prelimAdd from '../../../assets/images/prelimAdd.svg'
import deleteBtn from '../../../assets/images/delete.svg'
import editBtn from '../../../assets/images/edit.svg'
import './PrelimAssessmentDetail.scss'
import { useDispatch, useSelector } from 'react-redux'
import { deletePreAssessmentData, downloadPreArrivalAssessment, getPreAssessmentData } from '../../../redux/actions/GuestDataActions/PrelimAssessmentActions'
import moment from 'moment'
import DeleteModal from '../../../components/DeleteModal/DeleteModal'
import { CircularProgress, Tooltip } from '@mui/material'
import * as routes from '../../../routes/route'
import GuestDetailsHeader from '../../../components/GuestDetailsHeader/GuestDetailsHeader'
import { downloadMedia } from '../../../utils/helpers/downloadMedia'
import  pdfIcon from '../../../assets/images/pdf.svg'

function PrelimAssessmentDetail() {
    const location = useLocation();
    const { guestId, userProgramId, type} = location.state;
    const guestDetailsReducer = useSelector(state=>state.guestDetailsReducer)
    const prelimAssessment = useSelector(state=> state.prelimAssessmentReducer?.prelimAssessment?.prelim_assesment_data)
    const downloadLoader = useSelector(state=> state.prelimAssessmentReducer.downloadLoader)
    const [deleteModal, setDeleteModal] = useState(false)
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const canWrite = (permissions?.prelim_assessment?.permissions || '').includes('write');
    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const data = [
        
        {
            title: "Current Health Complaints (Physical)",
            value: prelimAssessment?.is_current_health_complaint_physical ? prelimAssessment?.health_complaint_keywords_physical : "No"
        },
        {
            title: "Current Health Complaints (Mental)",
            value: prelimAssessment?.is_current_health_complaint_mental ? prelimAssessment?.health_complaint_keywords_mental : "No"
        },

        {
            title: "Past Medical History / Surgical History",
            value: prelimAssessment?.is_past_medical_history ? prelimAssessment?.past_medical_history : "No"
        },
        {
            title: "Current Medications",
            value: prelimAssessment?.is_current_medications  ? prelimAssessment?.current_medications : "No"
        },
        {
            title: "Family History",
            value: prelimAssessment?.family_history
        },
        {
            title: "Diet",
            value: prelimAssessment?.diet
        },
        {
            title: "Allergies Related To Food, Chemicals, Dust, Pollen, Pets, Medicines Etc",
            value: prelimAssessment?.is_allergies ? prelimAssessment?.allergies_keywords : "No"
        },
        {
            title: "Objectives Of The Programme",
            value: prelimAssessment?.objectives || 'N/A'
        },
        {
            title: "Recommended Stay Duration",
            value: prelimAssessment?.recommended_stay_duration
        },
        {
            title: "Recommended Programme",
            value: prelimAssessment?.recommended_program
        },
        {
            title: "Medical Reports Requested for",
            value: prelimAssessment?.medical_reports_requested_for
        },
        {
            title: "Specific requests from guest",
            value: prelimAssessment?.specific_request_from_guest
        },
    ]

    
  const downloadPDF=()=>{
    const onSuccessCallback = (data)=>{
      downloadMedia({data, fileName: `${guestDetailsReducer.guestDetails?.first_name} ${guestDetailsReducer.guestDetails?.last_name} - Pre Arrival Consultation.pdf`})
    }
    const data = {
      guestId, 
      userProgramId, 
      onSuccessCallback
    }
    dispatch(downloadPreArrivalAssessment(data))
  }

    const deleteAssessment  = ()=>{
        dispatch(deletePreAssessmentData({prelim_assessment_id: prelimAssessment?.id, type, user_program_id: userProgramId}))
        setDeleteModal(false)
    }

    useEffect(()=>{
        if(guestId && userProgramId){
            dispatch(getPreAssessmentData({guestId, userProgramId}))
        }
    },[])


  return (
        <>
            <div className="view-prelim-assess-container">
                <div className="container h-100">
                    <div className="view-prelim-assess-heading">
                        Pre Arrival Consultation
                    </div>

                    {   loading && !deleteModal && (guestId !== guestDetails?.user_id) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                        <div className="pt-4 pb-5">
                            <div className="view-prelim-assess-content">
                                <GuestDetailsHeader guestId={guestId} editOption={true} />

                                {
                                    (Array.isArray(prelimAssessment)) ?
                                    
                                    <div className="guest-add">
                                        {
                                            canWrite
                                            ?
                                            <div data-testid="add-btn" className="add" onClick={()=>navigate(routes.ADD_PRELIM_ASSESMENT_ENDPOINT, {state: { guestId, userProgramId , type}})}>
                                                <img src={prelimAdd} alt="" />
                                                <div className='text'>Fill Guest Data</div>
                                            </div>
                                            :
                                            <div className="w-100 d-flex align-items-center justify-content-center message">
                                                <span>Pre Arrival Consultation is not yet added.</span>
                                            </div>
                                        }
                                    </div>
                                    :
                                    (prelimAssessment) ?

                                    <div className="guest-assess-data">
                                        <div className="last-update">
                                            <div className='date'>Last Update On: {moment(prelimAssessment?.updation_datetime).format('MMM DD, YYYY')}</div>
                                            {
                                                canWrite 
                                                ?
                                                <div className="icons">
                                                    <div className="vhr"></div>
                                                    {
                                                        downloadLoader ?
                                                        <CircularProgress sx={{'color': '#5ebd78', marginRight: '2px'}} size={18} />
                                                        :
                                                        <Tooltip title="Download PDF">
                                                            <div className='me-2' style={{width: '25px'}} onClick={downloadPDF}>
                                                            <img alt="print-btn" className="" src={pdfIcon}/>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    <img src={editBtn} alt="edit-btn" className="" onClick={()=>navigate(routes.EDIT_PRELIM_ASSESMENT_ENDPOINT, {state: { guestId, userProgramId }})}/>
                                                    <img src={deleteBtn} alt="del-btn" className="" onClick={()=>setDeleteModal(true)}/>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>

                                        <div className="hr"></div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="data-row">
                                                    <div className="title">Height: </div>
                                                    <div className="value">{prelimAssessment?.user_height} cm ({(prelimAssessment?.user_height * 0.0328084).toFixed(2)} ft)</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="data-row">
                                                    <div className="title">Weight: </div>
                                                    <div className="value">{prelimAssessment?.user_weight} kg ({(prelimAssessment?.user_weight * 2.20462).toFixed(2)} lb)</div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            data.map((item, index)=>{
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="hr"></div>

                                                        <div className="data-row">
                                                            <div className="title">{item.title}</div>
                                                            <div className="value">
                                                                {
                                                                    Array.isArray(item.value) ?
                                                                    <div>
                                                                        {
                                                                            item.value?.map((subItem, index)=>(
                                                                                <div className='d-flex mt-3'>
                                                                                    <div className='me-4'>&#10687;</div>
                                                                                    <div>
                                                                                        <div className='d-flex'><span className='subtitle'>Condition - </span><div className='subvalue'>{subItem.condition || '-'}</div></div>
                                                                                        {
                                                                                            item.title=== 'Past Medical History / Surgical History' ?
                                                                                            <div className='d-flex'><span className='subtitle'>Period - </span> <div className='subvalue'>{subItem.period || '-'}</div></div>
                                                                                            :
                                                                                            <>
                                                                                                <div className='d-flex'><span className='subtitle'>Intensity - </span> <div className='subvalue'>{subItem.intensity || '-'}</div></div>
                                                                                                <div className='d-flex'><span className='subtitle'>Duration - </span><div className='subvalue'>{subItem.duration || '-'}</div></div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    :
                                                                    (item.value  || '-')
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    }                
                </div>
            </div>
            <DeleteModal 
                deleteModal={deleteModal} 
                setDeleteModal={setDeleteModal}
                name="assessment"
                onDelete = {deleteAssessment }
            />
        </>
  )
}

export default PrelimAssessmentDetail