import React from 'react'
import './TextField.scss'
import { useField } from 'formik';

function TextField({name, unit, type, placeholder, testID, rows='5'}) {
  const props =  type==="numeric" ? {name, type:"text"} : {name}
  const [field, meta] =useField(props)

  return (
    <div className='prelim-text-field'>
      <div className="input">
        {
          type=== "numeric"
          ?
          <input type="number"
            maxLength="10"
            autoComplete='off'
            name={name} 
            pattern="[0-9]*"
            placeholder={placeholder}
          {...field}
          data-testid = {testID}
           />
          :
          <textarea  
            name={name} 
            rows={rows} 
            placeholder={placeholder}
            {...field}
            data-testid = {testID}
          >
          
          </textarea>
        }
        <div className='end-text'>{unit}</div>
      </div>
    </div>
  )
}

export default TextField