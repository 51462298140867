import React, { useCallback, useEffect, useState } from 'react'
import './AddNotes.scss'
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditorToolbar, { modules, formats }  from '../../../components/EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill';
import { CircularProgress } from '@mui/material';
import {noteTypes} from '../../../const/dropdownOptions'
import Select from "react-select";
import openEyeBtn from '../../../assets/images/eye-open.svg'
import closeEyeBtn from '../../../assets/images/eye-close.svg'  
import { notify } from '../../../utils/notification';
import { addConsultationNotes, getNotesTypeList, getPreviousNoteByType } from '../../../redux/actions/GuestDataActions/ConsultationNotesActions';
import ChangeVisibilityModal from '../../../components/ChangeVisibilityModal/ChangeVisibilityModal';

const DietrayNoteField = [
  {label: "Detox Diet Days", key: 'detox_diet_days', type: 'input'},
  {label: "Liquid Diet Days", key: 'liquid_diet_days', type: 'input'},
  {label: "Recommended Diet", key: 'recommended_diet'},
  {label: "Allergies", key: 'allergies'},
  {label: "General Diet Information", key: 'general_diet_information'},
  {label: "General Beverage Information", key: 'general_beverage_information'},
]

function AddNotes() {
  const location = useLocation();
  const { guestId, userProgramId , date } = location.state;
  const [noteType, setNoteType] = useState('')
  const [eye, setEye] = useState(false)
  const [content, setContent] = useState('')
  const [visibilityModal, setVisibilityModal] =useState(false)
  const dispatch = useDispatch()
  const navigate= useNavigate()
  const consultationNotesData = useSelector(state=> state.consultationNotesReducer)
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;

  const handleProcedureContentChange = (content, delta, source, editor) => {
    editor.getHTML(); // rich text
    editor.getText(); // plain text
    editor.getLength(); // number of characters
    setContent(content);
  };

  const handleEye = ()=>{
    setEye(prev=> !prev);
    setVisibilityModal(false)
}

  const handleSubmit = ()=>{
    if(!content || !noteType){
      notify('warning', 'Please Fill all Fields')
    }else{
      dispatch(addConsultationNotes({
        guestId,
        userProgramId,
        isVisible: eye,
        content : noteType?.label==='Dietary Notes' ? JSON.stringify(content) : content,
        noteType: noteType?.label,
        date
      }))

    }
  }
  
  const setDietNote = (key, value)=>{
    setContent(prev=>{
      let data = { ...prev}
      data[key] = value;
      return data;
    })
  }

  const handleBlur = useCallback(() => {
      setContent((prevText) => prevText);
  }, [content]);

  // useEffect(()=>{
  //   if(noteType?.label)
  //     dispatch(getPreviousNoteByType({
  //       userProgramId,
  //       noteType: noteType?.label
  //     }))
  // }, [noteType])

  useEffect(()=>{
    if(noteType?.label)
    setContent(noteType?.label==='Dietary Notes' && consultationNotesData.previousNote?.notes_detail ? JSON.parse(consultationNotesData.previousNote?.notes_detail) : consultationNotesData.previousNote?.notes_detail || '')
  }, [consultationNotesData.previousNote])

  useEffect(()=>{
    dispatch(getNotesTypeList())
  }, [])

  return (
    <>
      <div className="add-notes-container">
        <div className="container h-100">
            <div className="add-notes-heading">
              Consultant Recommendations
            </div>

            <div className="pt-4 pb-5">
              <div className="add-notes-content">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div className="heading">Add Note</div>
                  <div>
                    <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                    <button className="edit-btn" type='submit' onClick={handleSubmit}>
                      {
                        consultationNotesData?.updateLoader ?
                        <CircularProgress sx={{'color': '#fff'}} size={15}/>
                        :'Add'
                      }
                    </button>
                  </div>
                </div>

                <div className="form p-3">
                  <div className="d-flex justify-content-between ">
                    <div className='mb-3'>
                      <div className='title mb-2'>Select Note Type</div>
                      <Select
                          className="name-select"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={
                            consultationNotesData?.notesType?.map(item=> ({
                              label: item.title,
                              value: item.title
                            }))?.filter(item => item.label !=='Dietary Notes' || permissions?.dietary_notes?.permissions?.includes('write'))
                          }
                          placeholder='Select'
                          onChange={(e)=>{ setNoteType(e)}}
                      />
                    </div>
                    <div className='visible-btn' onClick={()=>setVisibilityModal(true)}>
                      <img src={eye ? openEyeBtn : closeEyeBtn} alt="eye-btn" className="me-2"/>
                      <span className="title">{eye ? 'Visible to Guest' : 'Hide from Guest'}</span>
                    </div>
                  </div>

                  {
                        noteType?.label ==='Dietary Notes' ?
                        <div>
                          {
                            DietrayNoteField?.map((item, index)=>(
                              <div className="data-row" key={index}>
                                <div className="title">{item.label}</div>
                                <div className='date-input'>
                                  {
                                    item.type==='input'?
                                    <div className="input">
                                          <input type="text" name="" id="" placeholder='Enter Keywords' value={content?.[item.key] || ''} onChange={e=>setDietNote(item.key, e.target.value)}/>
                                    </div>
                                    :
                                    <div className="textarea">
                                          <textarea rows={4} type="text" name="" id="" placeholder='Enter Keywords' value={content?.[item.key] || ''} onChange={e=>setDietNote(item.key, e.target.value)}/>
                                    </div>
                                  }
                                </div>
                              </div>
                            ))
                          }
                        </div>
                        :
                        <div>
                          <EditorToolbar />
                          <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={handleProcedureContentChange}
                            onBlur={handleBlur}
                            placeholder='Enter Keywords ...'
                            formats={formats}
                            modules={modules}
                          />
                        </div>
                      }
                </div>

              </div>
            </div>
          </div>
          <ChangeVisibilityModal
            warnModal={visibilityModal} 
            setWarnModal={setVisibilityModal} 
            onYes={handleEye}
            hide={eye}
          />
        </div>
      </>
  )
}

export default AddNotes
