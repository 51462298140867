//------------AUTH ACTIONS--------------//
export const LOGIN = 'LOGIN';

export const LOGIN_REQUEST_START = 'LOGIN_REQUEST_START';
export const LOGIN_REQUEST_END = 'LOGIN_REQUEST_END';
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_USERDATA = 'CLEAR_USERDATA';

export const LOGOUT = 'LOGOUT';

export const LOGOUT_REQUEST_START = 'LOGOUT_REQUEST_START';
export const LOGOUT_REQUEST_END = 'LOGOUT_REQUEST_END';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


//------------LOGIN VERIFY OTP ACTIONS--------------//
export const LOGIN_VERIFY_OTP = 'LOGIN_VERIFY_OTP';
export const LOGIN_RESEND_OTP = 'LOGIN_RESEND_OTP';

//------------UPDATE ACTIONS--------------//
export const UPDATE_MOBILE = 'UPDATE_MOBILE';


//------------UPDATE VERIFY OTP ACTIONS--------------//
export const UPDATE_VERIFY_OTP = 'UPDATE_VERIFY_OTP';
export const UPDATE_RESEND_OTP = 'UPDATE_RESEND_OTP';

//------------OTP REDUCERS--------------//
export const CALL_REMOVE_VERIFICATION_DATA = 'CALL_REMOVE_VERIFICATION_DATA';

export const SET_VERIFICATION_DATA = 'SET_VERIFICATION_DATA';
export const REMOVE_VERIFICATION_DATA = 'REMOVE_VERIFICATION_DATA';
export const SET_VERIFY_SUCCESS = 'SET_VERIFY_SUCCESS';
export const SET_VERIFY_FAIL = 'SET_VERIFY_FAIL';
export const OTP_REQUEST_START = 'OTP_REQUEST_START';
export const OTP_REQUEST_END = 'OTP_REQUEST_END';

//------------USER PROFILE ACTIONS--------------//
export const VIEW_PROFILE = "VIEW_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"

export const UPDATE_GUEST_DOB = 'UPDATE_GUEST_DOB';
export const UPDATE_GUEST_DOB_SUCCESS = 'UPDATE_GUEST_DOB_SUCCESS';
export const UPDATE_GUEST_DOB_FALIURE = 'UPDATE_GUEST_DOB_FALIURE';



//------------GUEST LIST--------------//
export const GET_GUEST_LIST = 'GET_GUEST_LIST';

export const GUEST_LIST_LOADING = 'GUEST_LIST_LOADING';
export const SET_TENTATIVE_GUEST = 'SET_TENTATIVE_GUEST';
export const SET_UPCOMING_GUEST = 'SET_UPCOMING_GUEST';
export const SET_ACTIVE_GUEST = 'SET_ACTIVE_GUEST';
export const SET_COMPLETED_GUEST = 'SET_COMPLETED_GUEST';
export const GUEST_LIST_FALIURE = 'GUEST_LIST_FALIURE';

export const UPDATE_GUEST_LIST = 'UPDATE_GUEST_LIST';

//------------GUSET DETAILS--------------//
export const GUEST_DETAILS = 'GUEST_DETAILS';

export const GUEST_DETAILS_SUCCESS = 'GUEST_DETAILS_SUCCESS';
export const GUEST_DETAILS_FAILURE = 'GUEST_DETAILS_FAILURE';


//------------PRELIM ASSESSMENT--------------//
export const ADD_PRE_ASSESSMENT = 'ADD_PRE_ASSESSMENT';
export const GET_PRE_ASSESSMENT = 'GET_PRE_ASSESSMENT';
export const UPDATE_PRE_ASSESSMENT = 'UPDATE_PRE_ASSESSMENT';
export const DEL_PRE_ASSESSMENT = 'DEL_PRE_ASSESSMENT';


export const PRE_ASSESSMENT_LOADING = 'PRE_ASSESSMENT_LOADING';

export const GET_PRE_ASSESSMENT_DATA_SUCCESS = 'GET_PRE_ASSESSMENT_DATA_SUCCESS';
export const GET_PRE_ASSESSMENT_DATA_FALIURE = 'GET_PRE_ASSESSMENT_DATA_FALIURE';


export const DEL_PRE_ASSESSMENT_DATA_SUCCESS = 'DEL_PRE_ASSESSMENT_DATA_SUCCESS';
export const DEL_PRE_ASSESSMENT_DATA_FALIURE = 'DEL_PRE_ASSESSMENT_DATA_FALIURE';

export const ADD_PRE_ASSESSMENT_DATA_SUCCESS = 'ADD_PRE_ASSESSMENT_DATA_SUCCESS';
export const ADD_PRE_ASSESSMENT_DATA_FALIURE = 'ADD_PRE_ASSESSMENT_DATA_FALIURE';

export const UPDATE_PRE_ASSESSMENT_DATA_SUCCESS = 'UPDATE_PRE_ASSESSMENT_DATA_SUCCESS';
export const UPDATE_PRE_ASSESSMENT_DATA_FALIURE = 'UPDATE_PRE_ASSESSMENT_DATA_FALIURE';

export const DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF = 'DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF';
export const DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_SUCCESS = 'DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_SUCCESS';
export const DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_FALIURE = 'DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_FALIURE';

//------------WELLBEING REPORT ASSESSMENT--------------//
export const WELLBEING_REPORT_LOADING = 'WELLBEING_REPORT_LOADING';

export const VIEW_WELLBEING_REPORT = 'VIEW_WELLBEING_REPORT';

export const WELLBEING_REPORT_SUCCESS = 'WELLBEING_REPORT_SUCCESS';
export const WELLBEING_REPORT_FAILURE = 'WELLBEING_REPORT_FAILURE';
export const CLEAR_WELLBEING_REPORT = 'CLEAR_WELLBEING_REPORT';

//------------FULL WELLBEING REPORT ASSESSMENT--------------//
export const VIEW_FULL_WELLBEING_REPORT = 'VIEW_FULL_WELLBEING_REPORT';

export const FULL_WELLBEING_REPORT_SUCCESS = 'FULL_WELLBEING_REPORT_SUCCESS';
export const FULL_WELLBEING_REPORT_FAILURE = 'FULL_WELLBEING_REPORT_FAILURE';

//------------EDIT WELLBEING REPORT ASSESSMENT DATA--------------//
export const GET_WELLBEING_REPORT_EDIT_DATA = 'GET_WELLBEING_REPORT_EDIT_DATA';
export const DELETE_WELLBEING_REPORT_EDIT_DATA = 'DELETE_WELLBEING_REPORT_EDIT_DATA';


export const GET_WELLBEING_REPORT_EDIT_DATA_SUCCESS = 'GET_WELLBEING_REPORT_EDIT_DATA_SUCCESS';
export const GET_WELLBEING_REPORT_EDIT_DATA_FAILURE = 'GET_WELLBEING_REPORT_EDIT_DATA_FAILURE';
export const CLEAR_WELLBEING_REPORT_EDIT_DATA = 'CLEAR_WELLBEING_REPORT_EDIT_DATA';

//------------UPDATE WELLBEING REPORT ASSESSMENT DATA--------------//
export const UPDATE_WELLBEING_REPORT_DATA = 'UPDATE_WELLBEING_REPORT_DATA';

export const UPDATE_WELLBEING_REPORT_DATA_SUCCESS = 'UPDATE_WELLBEING_REPORT_DATA_SUCCESS';
export const UPDATE_WELLBEING_REPORT_DATA_FAILURE = 'UPDATE_WELLBEING_REPORT_DATA_FAILURE';
export const FINAL_UPDATE_WELLBEING_REPORT_DATA_SUCCESS = 'FINAL_UPDATE_WELLBEING_REPORT_DATA_SUCCESS';

export const DOWNLOAD_WELLBEING_REPORT_PDF = 'DOWNLOAD_WELLBEING_REPORT_PDF';
export const DOWNLOAD_WELLBEING_REPORT_PDF_SUCCESS = 'DOWNLOAD_WELLBEING_REPORT_PDF_SUCCESS';
export const DOWNLOAD_WELLBEING_REPORT_PDF_FALIURE = 'DOWNLOAD_WELLBEING_REPORT_PDF_FALIURE';

//------------ ASSESSMENT SECTIONS DATA--------------//
export const ASSESSMENT_SECTIONS = 'ASSESSMENT_SECTIONS';

export const SET_ASSESSMENT_SECTIONS = 'SET_ASSESSMENT_SECTIONS';

//------------ REOPEN SECTIONS DATA--------------//
export const REOPEN_ASSESSMENT_FOR_GUEST = 'REOPEN_ASSESSMENT_FOR_GUEST';


//------------UPDATE WELLBEING REPORT VISIBLE Status ASSESSMENT--------------//
export const WELLBEING_REPORT_VISIBLE_STATUS = 'WELLBEING_REPORT_VISIBLE_STATUS';

export const OPEN_HEALTH_ASSESSMENT = 'OPEN_HEALTH_ASSESSMENT';
export const OPEN_HEALTH_ASSESSMENT_SUCCESS = 'OPEN_HEALTH_ASSESSMENT_SUCCESS';
export const OPEN_HEALTH_ASSESSMENT_FALIURE = 'OPEN_HEALTH_ASSESSMENT_FALIURE';

//------------SEARCH--------------//
export const GUEST_NAME_LIST = 'GUEST_NAME_LIST';
export const GUEST_NAME_LIST_SUCCESS = 'GUEST_NAME_LIST_SUCCESS';
export const GUEST_NAME_LIST_FALIURE = 'GUEST_NAME_LIST_FALIURE';

export const SEARCH_GUEST_LIST_RESULT = 'SEARCH_GUEST_LIST_RESULT';
export const SEARCH_GUEST_LIST_RESULT_SUCCESS = 'SEARCH_GUEST_LIST_RESULT_SUCCESS';
export const SEARCH_GUEST_LIST_RESULT_FALIURE = 'SEARCH_GUEST_LIST_RESULT_FALIURE';
export const CLEAR_SEARCH_GUEST_LIST_RESULT = 'CLEAR_SEARCH_GUEST_LIST_RESULT';

export const VIEW_GUEST_PROFILE = 'VIEW_GUEST_PROFILE';


//------------PREPARATORY DIET ASSESSMENT--------------/
export const GET_PREPARATORY_DIET_DATA = 'GET_PREPARATORY_DIET_DATA';
export const GET_PREPARATORY_DIET_DATA_SUCCESS = 'GET_PREPARATORY_DIET_DATA_SUCCESS';
export const GET_PREPARATORY_DIET_DATA_FALIURE = 'GET_PREPARATORY_DIET_DATA_FALIURE';

export const DEL_PREPARATORY_DIET_DATA = 'DEL_PREPARATORY_DIET_DATA';
export const DEL_PREPARATORY_DIET_DATA_SUCCESS = 'DEL_PREPARATORY_DIET_DATA_SUCCESS';
export const DEL_PREPARATORY_DIET_DATA_FALIURE = 'DEL_PREPARATORY_DIET_DATA_FALIURE';

export const UPDATE_PREPARATORY_DIET_DATA = 'UPDATE_PREPARATORY_DIET_DATA';
export const UPDATE_PREPARATORY_DIET_DATA_SUCCESS = 'UPDATE_PREPARATORY_DIET_DATA_SUCCESS';
export const UPDATE_PREPARATORY_DIET_DATA_FALIURE = 'UPDATE_PREPARATORY_DIET_DATA_FALIURE';

export const UPDATE_PREPARATORY_DIET_VISIBLE_STATUS = 'UPDATE_PREPARATORY_DIET_VISIBLE_STATUS';
export const UPDATE_PREPARATORY_DIET_VISIBLE_STATUS_SUCCESS = 'UPDATE_PREPARATORY_DIET_VISIBLE_STATUS_SUCCESS';
export const UPDATE_PREPARATORY_DIET_VISIBLE_STATUS_FALIURE = 'UPDATE_PREPARATORY_DIET_VISIBLE_STATUS_FALIURE';


//------------CONSULTATION NOTES --------------/
export const GET_CONSULTATION_NOTES_DATA = 'GET_CONSULTATION_NOTES_DATA';
export const GET_CONSULTATION_NOTES_DATA_SUCCESS = 'GET_CONSULTATION_NOTES_DATA_SUCCESS';
export const GET_CONSULTATION_NOTES_DATA_FALIURE = 'GET_CONSULTATION_NOTES_DATA_FALIURE';

export const GET_NOTES_TYPE_LIST = 'GET_NOTES_TYPE_LIST';
export const GET_NOTES_TYPE_LIST_SUCCESS = 'GET_NOTES_TYPE_LIST_SUCCESS';
export const GET_NOTES_TYPE_LIST_FALIURE = 'GET_CONSULTATION_NOTES_DATA_FALIURE';

export const DEL_CONSULTATION_NOTES_DATA = 'DEL_CONSULTATION_NOTES_DATA';
export const DEL_CONSULTATION_NOTES_DATA_SUCCESS = 'DEL_CONSULTATION_NOTES_DATA_SUCCESS';
export const DEL_CONSULTATION_NOTES_DATA_FALIURE = 'DEL_CONSULTATION_NOTES_DATA_FALIURE';

export const ADD_CONSULTATION_NOTES_DATA = 'ADD_CONSULTATION_NOTES_DATA';
export const ADD_CONSULTATION_NOTES_DATA_SUCCESS = 'ADD_CONSULTATION_NOTES_DATA_SUCCESS';
export const ADD_CONSULTATION_NOTES_DATA_FALIURE = 'ADD_CONSULTATION_NOTES_DATA_FALIURE';

export const UPDATE_CONSULTATION_NOTES_DATA = 'UPDATE_CONSULTATION_NOTES_DATA';
export const UPDATE_CONSULTATION_NOTES_DATA_SUCCESS = 'UPDATE_CONSULTATION_NOTES_DATA_SUCCESS';
export const UPDATE_CONSULTATION_NOTES_DATA_FALIURE = 'UPDATE_CONSULTATION_NOTES_DATA_FALIURE';

export const UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS = 'UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS';
export const UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_SUCCESS = 'UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_SUCCESS';
export const UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_FALIURE = 'UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_FALIURE';

export const GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE = 'GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE';
export const GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_SUCCESS = 'GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_SUCCESS';
export const GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_FALIURE = 'GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_FALIURE';

//------------SESSION --------------/
export const GET_DAYWISE_USER_SCHEDULE = 'GET_DAYWISE_USER_SCHEDULE';
export const GET_DAYWISE_USER_SCHEDULE_SUCCESS = 'GET_DAYWISE_USER_SCHEDULE_SUCCESS';
export const GET_DAYWISE_USER_SCHEDULE_FALIURE = 'GET_DAYWISE_USER_SCHEDULE_FALIURE';

export const VIEW_SESSION_NOTES = 'VIEW_SESSION_NOTES';
export const VIEW_SESSION_NOTES_SUCCESS = 'VIEW_SESSION_NOTES_SUCCESS';
export const VIEW_SESSION_NOTES_FALIURE = 'VIEW_SESSION_NOTES_FALIURE';

export const DELETE_SESSION_NOTES = 'DELETE_SESSION_NOTES';
export const DELETE_SESSION_NOTES_SUCCESS = 'DELETE_SESSION_NOTES_SUCCESS';
export const DELETE_SESSION_NOTES_FALIURE = 'DELETE_SESSION_NOTES_FALIURE';

export const ADD_SESSION_NOTES = 'ADD_SESSION_NOTES';
export const ADD_SESSION_NOTES_SUCCESS = 'ADD_SESSION_NOTES_SUCCESS';
export const ADD_SESSION_NOTES_FALIURE = 'ADD_SESSION_NOTES_FALIURE';

//------------QUERY --------------/
export const GET_QUERY_LIST = 'GET_QUERY_LIST';
export const SET_ACTIVE_QUERY = 'SET_ACTIVE_QUERY';
export const SET_CLOSED_QUERY = 'SET_CLOSED_QUERY';
export const GET_QUERY_LIST_FALIURE = 'GET_QUERY_LIST_FALIURE';

export const GET_QUERY_MESSAGE = 'GET_QUERY_MESSAGE';
export const GET_QUERY_MESSAGE_SUCCESS = 'GET_QUERY_MESSAGE_SUCCESS';
export const GET_QUERY_MESSAGE_FALIURE = 'GET_QUERY_MESSAGE_FALIURE';

export const SEND_MESSAGE_IN_QUERY = 'SEND_MESSAGE_IN_QUERY';
export const SEND_MESSAGE_IN_QUERY_SUCCESS = 'SEND_MESSAGE_IN_QUERY_SUCCESS';
export const SEND_MESSAGE_IN_QUERY_FALIURE = 'SEND_MESSAGE_IN_QUERY_FALIURE';

export const GET_QUERY_DETAILS = 'GET_QUERY_DETAILS';
export const GET_QUERY_DETAILS_SUCCESS = 'GET_QUERY_DETAILS_SUCCESS';
export const GET_QUERY_DETAILS_FALIURE = 'GET_QUERY_DETAILS_FALIURE';

export const UPDATE_QUERY_STATUS = 'UPDATE_QUERY_STATUS';
export const UPDATE_QUERY_STATUS_SUCCESS = 'UPDATE_QUERY_STATUS_SUCCESS';
export const UPDATE_QUERY_STATUS_FALIURE = 'UPDATE_QUERY_STATUS_FALIURE';

//------------GROUP CLASSES/ ACTIVITY --------------/
export const GET_ACTIVITY_LIST_BY_DATE = 'GET_ACTIVITY_LIST_BY_DATE';
export const GET_ACTIVITY_LIST_BY_DATE_SUCCESS = 'GET_ACTIVITY_LIST_BY_DATE_SUCCESS';
export const GET_ACTIVITY_LIST_BY_DATE_FALIURE = 'GET_ACTIVITY_LIST_BY_DATE_FALIURE';

export const GET_ACTIVITY_DETAILS = 'GET_ACTIVITY_DETAILS';
export const GET_ACTIVITY_DETAILS_SUCCESS = 'GET_ACTIVITY_DETAILS_SUCCESS';
export const GET_ACTIVITY_DETAILS_FALIURE = 'GET_ACTIVITY_DETAILS_FALIURE';
export const CLEAR_ACTIVITY_DETAILS = 'CLEAR_ACTIVITY_DETAILS';


export const GET_INTERESTED_USER_LIST = 'GET_INTERESTED_USER_LIST';
export const GET_INTERESTED_USER_LIST_SUCCESS = 'GET_INTERESTED_USER_LIST_SUCCESS';
export const GET_INTERESTED_USER_LIST_FALIURE = 'GET_INTERESTEDUSER_LIST_FALIURE';

//------------ STATUS REPORT --------------/
export const GET_WELLNESS_STATUS_REPORT = 'GET_WELLNESS_STATUS_REPORT';
export const GET_WELLNESS_STATUS_REPORT_SUCCESS = 'GET_WELLNESS_STATUS_REPORT_SUCCESS';
export const GET_WELLNESS_STATUS_REPORT_FALIURE = 'GET_WELLNESS_STATUS_REPORT_FALIURE';

export const GET_STATUS_REPORT_FILTER_LIST = 'GET_STATUS_REPORT_FILTER_LIST';
export const GET_STATUS_REPORT_FILTER_LIST_SUCCESS = 'GET_STATUS_REPORT_FILTER_LIST_SUCCESS';
export const GET_STATUS_REPORT_FILTER_LIST_FALIURE = 'GET_STATUS_REPORT_FILTER_LIST_FALIURE';