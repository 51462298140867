import { 
    ADD_CONSULTATION_NOTES_DATA, 
    DEL_CONSULTATION_NOTES_DATA, 
    GET_CONSULTATION_NOTES_DATA, 
    GET_NOTES_TYPE_LIST, 
    GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE, 
    UPDATE_CONSULTATION_NOTES_DATA, 
    UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS
} from '../../constants';


export function getConsultationNotes(data) {
    return {
        type: GET_CONSULTATION_NOTES_DATA,
        data
    };
}

export function addConsultationNotes(data) {
    return {
        type: ADD_CONSULTATION_NOTES_DATA,
        data
    };
}

export function updateConsultationNotes(data) {
    return {
        type: UPDATE_CONSULTATION_NOTES_DATA,
        data
    };
}

export function deleteConsultationNotes(data) {
    return {
        type: DEL_CONSULTATION_NOTES_DATA,
        data
    };
}

export function updateConsultationNotesVisibleStatus(data) {
    return {
        type: UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS,
        data
    };
}

export function getPreviousNoteByType(data) {
    return {
        type: GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE,
        data
    };
}

export function getNotesTypeList(data) {
    return {
        type: GET_NOTES_TYPE_LIST,
        data
    };
}