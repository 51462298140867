import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary,  } from '@mui/material'
import moment from "moment";
import defaultUser from '../../../assets/images/defaultUser.png'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import './Guest.scss'
import * as routes from '../../../routes/route'

function Guest({item}) {

    const [imageUrl, setImageUrl] = useState(null);
    const navigate = useNavigate()
    const checkIn = moment(item?.check_in).format('MMM DD, dddd')
    const checkOut = moment(item?.check_out).format('MMM DD, dddd')
    const nights = moment(item?.check_out).diff(moment(item?.check_in), 'day')

    let guestType = ''
    if (moment().isSameOrBefore(moment(item?.check_in))) guestType = 'UpComing'
    else if (moment().isSameOrAfter(moment(item?.check_out))) guestType = 'Completed'
    else guestType = 'Active'

    useEffect(()=>{
        
        if(item.profile_pic.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, item.profile_pic);
    
                getDownloadURL(imageRef).then((url) => {
                    setImageUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){
                
            }
        }
  }, [item]);
  
  
  return (
    <div className='searched-guest-container' onClick={()=>{navigate(routes.SEARCH_RESULT_ENDPOINT, {state:{guestDetails: item, guestType}})}}>
        <div className='guest-data'>
            <div className="d-flex">
                <div className='profile-img'>
                    <img src={!item.profile_pic ? defaultUser : imageUrl } alt=""  width={"100%"}/>
                </div> 
                <div className='profile-content'>
                    <div className="guest-name">{item.first_name} {item.last_name}</div>
                    <div className="guest-order">{item.title}</div>
                    <div>
                        <div className='d-flex'>
                            <div className="guest-check">Check-In: &nbsp;</div>
                            <div className="date-time">{checkIn}</div>
                        </div>
                        <div className='d-flex'>
                            <div className="guest-check">Check-Out: &nbsp;</div>
                            <div className="date-time">{`${checkOut} (${nights} Nights)`}</div>
                        </div>  
                        <div className='d-flex'>
                            <div className="guest-check">Room No.: &nbsp;</div>
                            <div className="date-time">{item.room_no || '-'}</div>
                        </div>  
                        <div className='d-flex'>
                            <div className="guest-check">Lead Doctor: &nbsp;</div>
                            <div className="date-time">{item.assign_lead_full_name || '-'}</div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <Accordion
            disableGutters={true}
            >

            <AccordionSummary
                expandIcon={<></>}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{
                    'MuiAccordionSummary-content':{
                        flexGrow: 'unset'
                    }
                }}
            >
            <div className='accordian-title w-100'>
                <div className='d-flex justify-content-between'>
                    <div className='room-details'></div>
                    <div> <span className='payment-status'>{item?.payment_detail?.payment_status === 'Not Recieved' ? 'Payment Pending' : 'Payment Recieved'}</span> | {guestType}</div>
                </div>
            </div>
            </AccordionSummary>
        </Accordion>
    </div>
  )
}

export default Guest