import React, { useEffect, useState } from 'react'
import './TextField.scss'

function TextField({name, value, placeholder, heading, onChange}) {
  const [text, setText] = useState(value)

  useEffect(() => {
    if(value?.length)
      onChange(value)
  }, [])


  return (
    <div className='text-field'>
      {heading && <div className="heading my-2 mt-3">{heading}</div>}
      <div className="input">
        <textarea  
          name={name} 
          placeholder={placeholder}
          onChange={(e)=>{
            setText(e.target.value);
            onChange(e.target.value)
          }}
          value={text}
        >
        
        </textarea>
      </div>
    </div>
  )
}

export default TextField