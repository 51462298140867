export function descendingComparator(a, b, orderBy) {
    if(orderBy.includes("_datetime")){
      return new Date(a[orderBy]).valueOf() - new Date(b[orderBy]).valueOf()
    }else if(typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string'){
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
          return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
          return 1;
        }
    }else{
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
        return 1;
        }
    }
    return 0;
  }
  
export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export function validateDate(date) {
  if(date === null)
      return false;
  
  let d = new Date(date);
  return (d instanceof Date && !isNaN(d.valueOf()))
}

export function capitalize (sentence){
  if(!sentence)
    return ""
  const words = sentence.trim().split(/\s+/);

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
  }

  return words.join(" ");
}