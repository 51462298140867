import React, { useEffect, useState } from 'react'
import './EditWellbeingReport.scss'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { delteWellbeingReportEditData, getWellbeingReportEditData, updateWellbeingReport } from '../../../redux/actions/GuestDataActions/WellbeingReportActions';
import { CircularProgress } from '@mui/material';
import TextField from './components/TextField/TextField';
import CheckBoxOptions from './components/CheckBoxOptions/CheckBoxOptions';
import RadioButton from './components/RadioButton/RadioButton';
import moment from 'moment';
import { notify } from '../../../utils/notification';
import SuccessModal from '../../../components/SuccessModal/SuccessModal';
import pointerIcon from '../../../assets/images/pointer.svg'

function DatePicker({value, onChange, testID}){
    const [dob, setDob] = useState('');

    useEffect(() => {
        if(value?.length){
            onChange(value)
            setDob(value)
        }
      }, [])

    return <div className='date-input'>
            <div className="input">
                <input type="date" name="" id="" 
                    data-testid= {testID}
                    min={moment(new Date()).subtract(50, 'year').format('YYYY-MM-DD')} 
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    value={dob}
                    onChange={(e)=>{
                        setDob(e.target.value)
                        onChange(e.target.value)
                    }}/>
            </div>
        </div>
}

function EditWellbeingReport() {
    let sectionSchema = {
        section_id: NaN,
        section_name: "",
        weightage: "",
        position: NaN,
        total_sections: NaN,
        completed_sections_out_of_total: NaN,
        final_submit: 0,
        questions: []
      }
    const location = useLocation()
    const {user_self_assessment_id , guestId, gender, guestName} = location.state;
    const {editWellbeingReportData, loading, finalSubmit} = useSelector(state=> state.wellbeingReportReducer)
    const progress =(editWellbeingReportData?.position-1)/editWellbeingReportData?.total_sections*100;
    const [sectionData, setSectionData] = useState(sectionSchema);
    const [detectChange, setDetectChange] = useState(true);
    const [answers, setAnswers] = useState([]);
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()

    const Refresh = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
          });
    }

    const onCheck = (selected, tagName, optionIndex, questionIndex, question) => {
        const questionSchema = {
          question_id: question?.id,
          user_answer_id: question?.user_answer_id || 0,
          question_strength: question?.question_strength,
          question_weightage: question?.question_weightage,
          grade: "",
          option: { any_other_health_condiation: '', data: [] },
          type: ""
        };

        setAnswers(prev => {
            let userAnswers = prev;
            let que = userAnswers[questionIndex] || questionSchema
            let questionOption = que?.option;
            let data = [...questionOption?.data]
            let option = {
                option_tag_name: tagName || '',
                options: selected,
                user_input: data?.[optionIndex]?.user_input || ['']
            }
            data[optionIndex] = option
            questionOption.data = data;
            que.option = questionOption;
            userAnswers[questionIndex] = que;
            return userAnswers;
            })
        };

    const onChangeText = (type, value, tagName, optionIndex, questionIndex, question) => {
    const questionSchema = {
        question_id: question?.id,
        user_answer_id: question?.user_answer_id || 0,
        question_strength: question?.question_strength,
        question_weightage: question?.question_weightage,
        grade: "",
        option: { any_other_health_condiation: '', data: [] },
        type: ""
    };

    setAnswers(prev => {
        let userAnswers = prev;
        let que = userAnswers[questionIndex] || questionSchema
        let questionOption = que?.option;
        let data = [...questionOption?.data]

        if(type==="health condition"){
            questionOption.any_other_health_condiation = value;
        }else{
            
            let option = {
                option_tag_name: tagName || '',
                options: data[optionIndex]?.options || [],
                user_input: [value]
            }
            data[optionIndex] = option;
        }

            
            questionOption.data = data;
            que.option = questionOption;
            userAnswers[questionIndex] = que;
            return userAnswers;
        })
    };

    const onSelectRadio =(seletedOption, tagName, optionIndex, questionIndex, question)=>{

        let value = seletedOption?.ans || seletedOption
        const questionSchema = {
            question_id: question?.id,
            user_answer_id: question?.user_answer_id || 0,
            question_strength: question?.question_strength,
            question_weightage: question?.question_weightage,
            grade: "",
            option: { any_other_health_condiation: '', data: [] },
            type: ""
        };
    
        setAnswers(prev => {
            let userAnswers = prev;
            let que = userAnswers[questionIndex] || questionSchema

            if (question?.section_id === 1) {
                let questionOption = que?.option;

                let data = [...questionOption?.data]
                let option = {
                    option_tag_name: tagName || '',
                    options: [value],
                    user_input: data?.[optionIndex]?.user_input || ['']
                }

                data[optionIndex] = option
                questionOption.data = data;
                que.option = questionOption;
                que.grade = seletedOption?.grade || ""
            } else {
                que.option = value;
                que.grade = seletedOption?.grade || ""
            }
            userAnswers[questionIndex] = que;
            if (question?.id === 7 && value === 'Yes') {
                let ans8Index = userAnswers.findIndex((ans) => ans.question_id === 8);
                if (ans8Index > -1) {
                    userAnswers[ans8Index] = null
                }
            }
            return userAnswers;
        })
    }

    const onSelectDate = (date, tagName, optionIndex, questionIndex, question)=>{
        onSelectRadio(date, tagName, optionIndex, questionIndex, question)
    }

    const checkValidate = ()=>{

        let ans = answers.flatMap((item => item || []))
            
        if (editWellbeingReportData?.section_id === 1) {
            let qLength = (gender==="Male"
            ? editWellbeingReportData?.questions.slice(0, 6)
            : editWellbeingReportData?.questions.slice(6)).length

            let aLength = ans.length

            if (gender==="Male") {
                const allFieldFill= !answers.find((item)=>{
                    if(item?.option?.data?.length>1){
                        return item?.option?.data?.find((item2)=>{
                            return item2?.options?.length === 0
                        })
                    }else
                        return item?.option?.data?.[0]?.options?.length === 0
                })

                if (aLength === qLength && ans[0]?.option?.data?.length === 10 && allFieldFill)
                    return true

                return false;
            }else {
                let answerID7 = ans.find((i) => i.question_id === 7);
                let ans7 = answerID7?.option?.data?.[0]?.options?.[0]

                if (ans7 === 'Yes') {
                    ans = ans.filter(function( obj ) {
                        return obj.question_id !== 8;
                    });

                    const allFieldFill = !ans.find((item)=>{
                        return item?.option?.data?.[0]?.options?.length === 0
                    })
                    if (ans.length === 14 && allFieldFill) return true;
                    
                } else {
                  let answerID8 = ans.find((i) => i?.question_id === 8);
                  let ans8 = answerID8?.option?.data?.[0]?.options
                  if (ans8?.includes('Currently Pregnant')) {
                    let newAns = ans.filter((i) => [7, 8, 21].includes(i?.question_id))

                    const allFieldFill = !newAns.find((item)=>{
                        return item?.option?.data?.[0]?.options?.length === 0
                    })
                    if (newAns.length === 3 && allFieldFill) return true;

                  } else if (ans8?.includes('Menopause')) {
                        let newAns = ans.filter((i) => [7, 8, 17, 18, 19, 20, 21].includes(i?.question_id))
                        const allFieldFill = !newAns.find((item)=>{
                            //console.log(item?.option?.data?.[0]?.options)
                            return item?.option?.data?.[0]?.options?.length === 0
                        })
                        if (newAns.length === 7 && allFieldFill) return true;
                    }else{
                        const allFieldFill = !ans.find((item)=>{
                            return item?.option?.data?.[0]?.options?.length === 0
                        })

                        if (qLength === ans?.length && allFieldFill)
                            return true;
                    }
                }
            }
        }else {
            return !ans.find((item)=>{
                return item?.option?.length === 0
            })
        }
        return false;
    }

    const handleSubmit = ()=>{
        if(!checkValidate()){
            notify('warning', "Please fill all the fields")
        }else{
            const payload = {
                user_id: guestId,
                user_self_assessment_id: user_self_assessment_id,
                section_id: sectionData?.section_id,
                position: sectionData?.position,
                update_assessment: answers
            }
            dispatch(updateWellbeingReport(payload))
            setAnswers([])
        }
        // eslint-disable-next-line
    }

    useEffect(()=>{
        dispatch(getWellbeingReportEditData({is_edit_request: 1,  user_self_assessment_id }))

        return()=>{
            dispatch(delteWellbeingReportEditData())
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        setSectionData(editWellbeingReportData)
        if(!loading && editWellbeingReportData && Object.keys(editWellbeingReportData)?.length)
            Refresh()
        // eslint-disable-next-line
    }, [loading])

    useEffect(()=>{
        if(finalSubmit){
            setSuccess(true)
        }
    },[finalSubmit])
  
    return (
        <div className="edit-report-container">
            <div className="container h-100">
            <div className="edit-report-heading">
                Edit Assessment
            </div>
    
            {   
                loading && editWellbeingReportData && !Object.keys(editWellbeingReportData)?.length ?
                <div className="loader">
                    <CircularProgress sx={{'color': '#74613C'}}/>
                </div>
                :
                
                <div className="pt-4 pb-5">
                    {
                        editWellbeingReportData && Object.keys(editWellbeingReportData).length !==0
                        &&
                        <div className="edit-report-content">
                            <div className="d-flex justify-content-between">
                                <div className="title">{editWellbeingReportData?.section_name}</div>
                                <div className="count-section">Section {editWellbeingReportData?.position} of {editWellbeingReportData?.total_sections}</div>
                            </div>

                            <div className="position-relative">
                                <div className="progress-bar">
                                    <div className="completed" style={{width: `${progress}%`}}></div>
                                </div>
                                <img className='pointer' style={{marginLeft: `${progress}%`}} src={pointerIcon} alt="" />
                            </div>

                           
                            {
                                editWellbeingReportData?.questions.length &&
                                (editWellbeingReportData?.section_id === 1 
                                    ?gender==='Male' 
                                        ? editWellbeingReportData?.questions.slice(0, 6) 
                                        : editWellbeingReportData?.questions.slice(6)
                                    : editWellbeingReportData?.questions).map((item, index1)=>{

                                        if (item?.id === 8) {
                                            let answerID7 = answers.find((i) => i?.question_id === 7);
                                            let ans7 = answerID7?.option?.data?.[0]?.options?.[0]
                                            if (ans7 === 'Yes') return null;
                                        }
                      
                                          if (item?.id >= 9 && item?.id <= 20) {
                                            let answerID8 = answers.find((i) => i?.question_id === 8);
                                            let ans8 = answerID8?.option?.data?.[0]?.options
                                            if (ans8?.includes('Menopause') && item?.id <= 16) return null;
                                            if (ans8?.includes('Currently Pregnant') && item?.id <= 20) return null;
                                          }

                                    return (
                                        <div key={index1}>
                                            <div className='question-box'>
                                                <div className="question">
                                                    {index1 + 1}. {item?.id === 5 ? "Family History - " : ''} {item.question}
                                                </div>
                                                { 
                                                    (item.question_type === "MCQ&Input" || item.question_type === "MCQ" || item.question_type === "Radio" ) 
                                                    &&
                                                    <div className="muted-text">Select Option</div>
                                                }
                                                
                                                {
                                                    (Array.isArray(item?.options) ? item?.options: [item?.options]).map((subItem, subItemindex)=>{

                                                        let optionAnswer = item.answer?.data ? item.answer?.data[subItemindex] : item.answer

                                                        return (
                                                            <div className="sub-question-box" key={subItemindex}>
                                                                <div className="sub-question">
                                                                    {
                                                                        subItem?.option_tag_name &&
                                                                        <>
                                                                            {subItem.option_tag_name} 
                                                                            <div className="brown-hr"></div>
                                                                        </>
                                                                    }
                                                                </div>
                                                                {
                                                                    (item.question_type === "MCQ&Input" || item.question_type === "MCQ" )
                                                                    &&
                                                                    <CheckBoxOptions 
                                                                        options={subItem?.value || subItem?.options?.value} 
                                                                        answer={optionAnswer} 
                                                                        onSelect={(selectedItems)=>{onCheck(selectedItems, subItem?.option_tag_name || subItem?.options?.option_tag_name, subItemindex, index1, item)}}
                                                                        detectChange={detectChange}
                                                                        setDetectChange={setDetectChange}
                                                                    />
                                                                }

                                                                {
                                                                    (item.question_type === "Radio")
                                                                    &&
                                                                    <RadioButton 
                                                                        key={item?.id}
                                                                        options={subItem?.value || subItem?.options?.value} 
                                                                        answer={optionAnswer?.options?.[0] || optionAnswer} 
                                                                        onSelect={(option)=>{onSelectRadio(option, subItem?.options?.option_tag_name, subItemindex, index1, item)}}
                                                                        detectChange={detectChange}
                                                                        setDetectChange={setDetectChange}
                                                                    />
                                                                }

                                                                {
                                                                    (item.question_type === "Single Line")
                                                                    &&
                                                                    <div>
                                                                        <TextField 
                                                                            placeholder={"Enter Keywords ..."}
                                                                            onChange={(value)=>{onSelectRadio(value, subItem?.option_tag_name, subItemindex, index1, item)}}
                                                                            value={optionAnswer?.options?.[0]  || ''}/>
                                                                    </div>
                                                                }      

                                                                {
                                                                    (item.question_type === "Date")
                                                                    &&

                                                                    <div>
                                                                        <DatePicker
                                                                            value={optionAnswer?.options?.[0] || ''}
                                                                            onChange={(date)=>{
                                                                                onSelectDate(date, subItem?.option_tag_name, subItemindex, index1, item )
                                                                            }}
                                                                            testID ={`section${item.section_id}_quesID${item.id}`}
                                                                        />
                                                                    </div>
                                                                    
                                                                }      
                                        

                                                                {
                                                                    (item.question_type === "MCQ&Input" || item.question_type === "MCQ" || item.question_type === "Radio") && 
                                                                    (item?.section_id === 1 && ![5, 6, 11, 12, 13, 14, 19, 21].includes(item?.id) )
                                                                    &&
                                                                    <div>
                                                                        <TextField 
                                                                            heading={item?.question_type === 'Radio' ? "If Yes, Details" : "Describe the condition"}
                                                                            placeholder={"Enter your answer"}
                                                                            onChange={(value)=>{onChangeText("user input", value, subItem?.option_tag_name, subItemindex, index1, item)}}
                                                                            value={optionAnswer?.user_input[0] || ''}/>
                                                                    </div>
                                                                }

                                                                {
                                                                    (item?.section_id === 1 && ((item?.id === 1 && subItemindex===9) || item?.id === 5 || item?.id === 6))
                                                                    ?
                                                                    <div>
                                                                        <TextField 
                                                                            heading={(item?.section_id === 1 && item?.id === 6) ? "Remarks (details of the goals selected, if needed to elaborate)" : "Any other health condition"}
                                                                            placeholder={"Enter your answer"}
                                                                            onChange={(value)=>{onChangeText("health condition", value, subItem.option_tag_name, subItemindex, index1, item)}}
                                                                            value={item.answer?.any_other_health_condiation || ''}/>
                                                                            
                                                                    </div>
                                                                    :null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="grey-hr"></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        <div className="submit">
                            <button onClick={handleSubmit} data-testid="submit-btn">
                                {
                                    loading ? <CircularProgress size="15px" color="inherit" />
                                    :
                                    editWellbeingReportData?.position === editWellbeingReportData?.total_sections ? 'SUBMIT' : 'SAVE & NEXT'
                                }
                            </button>
                        </div>
                            
                    </div>
                    }
                    
                </div>
                }
            </div>
           
            <SuccessModal 
                successModal={success} 
                setSuccessModal={setSuccess} 
                guestName={guestName}
                onSuccess={()=>{window.history.back();}}
            />
      </div>
    )
}

export default EditWellbeingReport